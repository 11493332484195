import React, { useState, FormEvent } from "react";
import { CheckPassword } from "../../../../Components/RemoteRequests/checkPassword";
import "./index.css";
import { useUnlockContext } from "../../../../Context/UnlockContext";

function UnlockScreen() {
	const [password, setPassword] = useState<string>("");
	const [message, setMessage] = useState<string>("");
	const { setUnlocked } = useUnlockContext();

	// onSubmit function that calls CheckPassword and updates the message displayed to the user
	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const response = await CheckPassword(password);

		if (response.status === 200) {
			setUnlocked(true);
			sessionStorage.setItem("password", password);
			setMessage(response.message);
		} else {
			setUnlocked(false);
			setMessage(response.message);
		}
	};

	return (
		<div className="UnlockScreen-div">
			<div className="UnlockScreen-div-body">
				<h2 className="UnlockScreen-Header">PRIVATE AREA</h2>
				<h3 className="UnlockScreen-Text">
					Please enter unlock key to view the content of this page.
				</h3>
				<form className="UnlockScreen-form" onSubmit={onSubmit}>
					<input
						type="password"
						id="password"
						name="password"
						required
						placeholder="Enter password"
						maxLength={40}
						autoComplete="off"
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
							setMessage(""); // Clear the message when the user starts typing a new password
						}}
					/>
					<input type="submit" id="submit" name="submit" value="Log In" />
				</form>
				<p className="UnlockScreen-Message">{message}</p>
			</div>
		</div>
	);
}

export default UnlockScreen;
