import "./index.css";

function AboutMe() {
	return (
		<div className="about-me-div">
			<h3>A Passion for Software, A Journey of Discovery</h3>
			<p>
				Hello! I'm an aspiring software developer, embarking on a journey that's as unique
				as the code I write. My adventure began in the technical realm of mechatronics,
				where I spent six years in a technical high school. This experience laid the
				foundation for my technical skills. Initially drawn to mechanical engineering, I
				soon found my true passion in the digital world - creating code and designing
				software solutions. This epiphany prompted me to pivot towards software engineering,
				a field that constantly feeds my curiosity and creativity.
			</p>
			<h3>Software Development: A World of Possibilities</h3>
			<p>
				My expertise spans across various aspects of software development, including both
				front-end and back-end technologies. My portfolio, which you're currently viewing,
				showcases my interest in developing engaging web applications. It represents my
				first venture into creating a website from scratch, using React. Although my work
				with GE Additive has been bound by confidentiality, the experiences and skills I've
				acquired are invaluable and have broadened my perspective on software development.
			</p>
			<h3>Career Aspirations: Making an Impact</h3>
			<p>
				My career goals are continually evolving. I'm driven by the desire to develop
				software that makes a difference, aiming to reach a wide audience and simplify
				lives. While founding and leading a company is my ultimate aspiration, I'm currently
				exploring a variety of career paths in the tech industry. This approach allows me to
				embrace diverse opportunities and expand my skills with each new project.
			</p>
			<h3>A Unique Approach with Rapid Learning</h3>
			<p>
				I pride myself on my ability to tackle problems from various perspectives, often
				leading to innovative and more effective solutions. My quick learning capability
				ensures that I stay adaptable and constantly evolving in the ever-changing landscape
				of software development.
			</p>
			<h3>Life Beyond Code</h3>
			<p>
				Away from the keyboard, my hobbies include 3D printing, gaming, and tinkering with
				cars and bikes. These activities not only provide a much-needed diversion but also
				frequently inspire my approach to software development.
			</p>
		</div>
	);
}

export default AboutMe;
