import React, { createRef } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Menu from "./Menu";
import "./index.css";
import { useLocation, useOutlet } from "react-router-dom";
import { routes, RouteType } from "./routes";

const defaultRoute: RouteType = {
	path: "/",
	name: "",
	element: <></>,
	nodeRef: createRef<HTMLDivElement>(),
	locked: false,
	hidden: false,
};

function Content() {
	const location = useLocation();
	const currentOutlet = useOutlet();
	const route = routes.find((route) => route.path === location.pathname) ?? defaultRoute;

	return (
		<div className="Content">
			<Menu />
			<div className="view-content">
				<SwitchTransition>
					<CSSTransition
						key={location.pathname}
						nodeRef={route.nodeRef}
						timeout={500}
						classNames="fade"
						unmountOnExit
					>
						{() => (
							<div ref={route.nodeRef} className="fade">
								{route.element || currentOutlet}
							</div>
						)}
					</CSSTransition>
				</SwitchTransition>
			</div>
		</div>
	);
}

export default Content;
