import TimeLine from "../../../../Components/TimeLine";
import { useUnlockContext } from "../../../../Context/UnlockContext";
import UnlockScreen from "../UnlockScreen";
import "./index.css";

function Experience() {
	const { isUnlocked } = useUnlockContext();

	if (!isUnlocked) {
		return <UnlockScreen />;
	} else {
		return (
			<div className="exp-content">
				<div className="exp-body">
					<TimeLine
						title="TTL 3D-Printing inh. Stephan Dumitru"
						date="03/2018-06/2020"
						text={text1}
						isFirst
					/>
					<TimeLine
						title="Evoqua Water Technologies GmbH"
						date="10/2020-04/2021"
						text={text2}
					/>
					<TimeLine
						title="GE Additive (Concept Laser GmbH)"
						date="08/2022-current"
						text={text3}
						isLast
					/>
				</div>
			</div>
		);
	}
}

export default Experience;

const text1 = [
	"Location: Langenau, BW, Germany",
	"Position: Company founder",
	"3D Printing, CAD Modeling, Prototype Manufacturing",
	"Communicating with business and private clients",
	"Handling marketing and logistics",
];

const text2 = [
	"Location: Günzburg, BY, Germany",
	"Position: Dual student",
	"Programming micro controllers",
	"Familiarizing with the company's portfolio",
	"Maintaining mechanical parts",
];

const text3 = [
	"Location: Lichtenfels, BY, Germany",
	"Position: Working student in software engineering",
	"Frontend Development using React",
	"Backend Development with C#/.NET",
	"Feature Development video streaming and networking",
	"General Feature development",
];
