import React, { createRef } from "react";
import "./index.css";
import { NavLink } from "react-router-dom";
import { RouteType, routes } from "../routes";
import { ReactComponent as LockIcon } from "./../../../icons/lock-solid.svg";
import { useUnlockContext } from "../../../Context/UnlockContext";
import UnlockScreen from "./UnlockScreen";

function Menu() {
	return (
		<div className="Menu">
			<h1>Stephan Dumitru</h1>
			<h2>Full-Stack Software Engineer</h2>
			<div className="MenuList">{CreateMenuList()}</div>
		</div>
	);
}

function CreateMenuList() {
	const { isUnlocked } = useUnlockContext();

	return (
		<ul>
			{routes
				.filter((route) => !route.hidden) // Filter out hidden routes
				.map((route) => (
					<li key={route.path}>
						<NavLink to={route.path}>
							<div className="link-div">
								<span className="link-text">{route.name}</span>
								{route.locked && !isUnlocked ? (
									<LockIcon className="lock-icon" fill="currentColor" />
								) : (
									<></>
								)}
							</div>
						</NavLink>
					</li>
				))}
		</ul>
	);
}

const unlockScreenRoute: RouteType = {
	path: "/unlockscreen",
	name: "UnlockScreen",
	element: <UnlockScreen />,
	nodeRef: createRef<HTMLDivElement>(),
	locked: false,
	hidden: true,
};

export default Menu;
