import "./index.css";

function Contact() {
	return (
		<div className="contact-div">
			<div className="contact-div-body">
				<h2 className="contact-header">Contact me</h2>
				<form className="contact-from" autoComplete="on" name="Contact" method="post">
					<div className="contact-from-div">
						<input
							type="text"
							id="first-name"
							name="first-name"
							required
							placeholder="First Name"
							maxLength={30}
							autoComplete="off"
						/>
						<input
							type="text"
							id="last-name"
							name="last-name"
							required
							placeholder="Last Name"
							maxLength={30}
							autoComplete="off"
						/>
						<input
							type="text"
							id="mail"
							name="mail"
							required
							placeholder="E-Mail"
							maxLength={40}
							autoComplete="off"
						/>
						<input
							type="tel"
							id="tel"
							name="tel"
							placeholder="Phone Number (optional)"
							maxLength={20}
							autoComplete="off"
						/>
						<textarea
							id="message"
							name="message"
							required
							spellCheck
							placeholder="Message"
							maxLength={1000}
							onInput={(e) => auto_grow(e.target as HTMLTextAreaElement)}
							autoComplete="off"
						/>
						<input type="submit" id="submit" name="submit" value="Submit" />
						<span className="email">
							E-Mail:{" "}
							<a href="mailto:mail@stephan-dumitru.net" className="email-link">
								mail@stephan-dumitru.net
							</a>
						</span>
					</div>
				</form>
			</div>
		</div>
	);
}

function auto_grow(element: HTMLTextAreaElement) {
	element.style.height = "2.5em";
	element.style.height = element.scrollHeight + "px";
}

export default Contact;
