import "./index.css";

type TimeLineProp = {
	title: string;
	date: string;
	text: string[];
	isFirst?: boolean;
	isLast?: boolean;
};

function TimeLine({ title, date, text, isFirst, isLast }: TimeLineProp) {
	return (
		<div className="time-line-ele">
			<div className="time-line-text">
				<h2>{title}</h2>
				<h3>{date}</h3>
				<ul>
					{text.map((n) => (
						<li key={n}>{n}</li>
					))}
				</ul>
			</div>
			<div className="time-line-deco">
				<div className={isFirst ? "time-line-deco-top-first" : "time-line-deco-top"}></div>
				<div className="time-line-deco-mid"></div>
				<div className={isLast ? "time-line-deco-bot-last" : "time-line-deco-bot"}></div>
			</div>
		</div>
	);
}

export default TimeLine;
