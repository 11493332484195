import React, { FormEvent, useEffect, useState } from "react";
import Content from "./Content";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { useUnlockContext } from "../Context/UnlockContext";
import { CheckPassword } from "../Components/RemoteRequests/checkPassword";

function App() {
	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
	const [isScrolling, setIsScrolling] = useState(false);
	const [animation, setAnimation] = useState("");
	const [overflow, setOverflow] = useState("");
	const [hoveringClickable, setHoveringClickable] = useState(false);
	const [mousePressed, setMousePressed] = useState(false);

	useEffect(() => {
		const handleMouseMove = (e: MouseEvent) => {
			const offset = 10; // Assume 20 is the width and height of the cursor div, adjust as necessary
			setCursorPosition({ x: e.clientX - offset, y: e.clientY - offset });

			const element: HTMLElement | null = document.elementFromPoint(
				e.clientX,
				e.clientY
			) as HTMLElement;

			if (element) {
				const isClickable =
					element.tagName === "A" ||
					element.tagName === "BUTTON" ||
					element.tagName === "INPUT" ||
					element.tagName === "TE" ||
					element.getAttribute("role") === "button" ||
					element.hasAttribute("href") ||
					element.hasAttribute("onclick") || // element is clickable
					element.className === "link-text" ||
					element.className === "swiper-button-next" ||
					element.className === "swiper-button-prev";

				if (isClickable) {
					// If the cursor is over a clickable element
					setHoveringClickable(true);
					setOverflow("");
				} else {
					setHoveringClickable(false);
					// Check for vertical overflow
				}
				if (isAncestorOverflowed(element) && !isClickable) {
					// console.log("An ancestor of the hovered element has vertical overflow!");
					setOverflow("scroll-elem");
				} else {
					setOverflow(""); // No overflow and not clickable
				}
			}
		};

		const handleWheel = (e: WheelEvent) => {
			if (!isScrolling) {
				setIsScrolling(true);
				setAnimation(e.deltaY > 0 ? "scrollDown 0.5s linear" : "scrollUp 0.5s linear");

				setTimeout(() => {
					setAnimation("");
					setIsScrolling(false);
				}, 500);
			}
		};

		document.addEventListener("mousemove", handleMouseMove);
		window.addEventListener("wheel", handleWheel);

		return () => {
			// Cleanup the event listeners
			document.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("wheel", handleWheel);
		};
	}, [isScrolling]);

	useEffect(() => {
		const handleMouseDown = () => {
			setMousePressed(true);
		};

		const handleMouseUp = () => {
			setMousePressed(false);
		};

		document.addEventListener("mousedown", handleMouseDown);
		document.addEventListener("mouseup", handleMouseUp);

		return () => {
			// Cleanup the event listeners
			document.removeEventListener("mousedown", handleMouseDown);
			document.removeEventListener("mouseup", handleMouseUp);
		};
	}, []);

	const { setUnlocked } = useUnlockContext();

	useEffect(() => {
		const sessionPassword = sessionStorage.getItem("password");
		if (sessionPassword != null) {
			(async () => {
				const response = await CheckPassword(sessionPassword);

				if (response.status === 200) {
					setUnlocked(true);
				} else {
					setUnlocked(false);
				}
			})();
		}
	}, [setUnlocked]);

	return (
		<div className="App">
			<div
				className={`cursor ${animation} ${overflow} ${
					hoveringClickable ? "hoveringClickable" : ""
				}
				}
				${mousePressed ? "mousePressed" : ""}`}
				style={{
					left: `${cursorPosition.x}px`,
					top: `${cursorPosition.y}px`,
					animation: animation,
				}}
			>
				<div
					className={`inner-cursor ${hoveringClickable ? "hoveringClickable" : ""} ${
						mousePressed ? "mousePressed" : ""
					} `}
				></div>
			</div>
			<BrowserRouter>
				<Content />
			</BrowserRouter>
		</div>
	);
}

export default App;

function hasVerticalOverflow(element: HTMLElement): boolean {
	// console.log(element.scrollHeight, element.clientHeight);
	return element.scrollHeight > element.clientHeight;
}

function isAncestorOverflowed(element: HTMLElement | null): boolean {
	while (element) {
		if (hasVerticalOverflow(element)) {
			// console.log(element.className, element.tagName);
			return true;
		}
		element = element.parentElement;
	}
	return false;
}
