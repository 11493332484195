import "./skillsComps.css";

type SkillsHeaderProps = {
	title: string;
};

export function SkillsHeader(props: SkillsHeaderProps) {
	return (
		<div className="header-div">
			<h1 className="header">[{props.title}]</h1>
		</div>
	);
}

type SkillsElemProps = {
	name: string;
	skill: number;
	half?: boolean;
};

export function SkillsElem(props: SkillsElemProps) {
	const { name, skill, half } = props;
	// The logic for determining how many full, half, and empty circles to render
	let fullCircles = Math.min(skill, 5); // max 5 full circles
	let halfCircle = half && skill < 5; // show half circle only if we have less than 5 full circles
	let emptyCircles = 5 - fullCircles - (halfCircle ? 1 : 0); // fill the rest with empty circles

	return (
		<div className="SkillsElem-div">
			<span className="name">{name}</span>
			<div className="circle-container">
				{Array.from({ length: fullCircles }).map((_, index) => (
					<div key={index} className="full-circle"></div>
				))}
				{halfCircle && (
					<div className="half-circle">
						<div className="half-circle-ring"></div>
						<div className="half-circle-filling"></div>
					</div>
				)}
				{Array.from({ length: emptyCircles }).map((_, index) => (
					<div key={index} className="empty-circle"></div>
				))}
			</div>
		</div>
	);
}
