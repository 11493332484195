import Home from "./Menu/Home";
import AboutMe from "./Menu/AboutMe";
import Education from "./Menu/Education";
import Experience from "./Menu/Experience";
import Skills from "./Menu/Skills";
import Projects from "./Menu/Projects";
import Interests from "./Menu/Interests";
import Contact from "./Menu/Contact";
import { createRef } from "react";
import UnlockScreen from "./Menu/UnlockScreen";

export type RouteType = {
	path: string;
	name: string;
	element: JSX.Element;
	nodeRef: React.RefObject<HTMLDivElement>;
	locked: boolean;
	hidden: boolean;
};

export const routes: Array<RouteType> = [
	{
		path: "/",
		name: "Home",
		element: <Home />,
		nodeRef: createRef<HTMLDivElement>(),
		locked: false,
		hidden: false,
	},
	{
		path: "/about-me",
		name: "About me",
		element: <AboutMe />,
		nodeRef: createRef<HTMLDivElement>(),
		locked: false,
		hidden: false,
	},
	{
		path: "/education",
		name: "Education",
		element: <Education />,
		nodeRef: createRef<HTMLDivElement>(),
		locked: true,
		hidden: false,
	},
	{
		path: "/experience",
		name: "Experience",
		element: <Experience />,
		nodeRef: createRef<HTMLDivElement>(),
		locked: true,
		hidden: false,
	},
	{
		path: "/skills",
		name: "Skills",
		element: <Skills />,
		nodeRef: createRef<HTMLDivElement>(),
		locked: false,
		hidden: false,
	},
	{
		path: "/projects",
		name: "Projects",
		element: <Projects />,
		nodeRef: createRef<HTMLDivElement>(),
		locked: false,
		hidden: false,
	},
	// {
	// 	path: "/interests",
	// 	name: "Interests",
	// 	element: <Interests />,
	// 	nodeRef: createRef<HTMLDivElement>(),
	// 	locked: false,
	// 	hidden: false,
	// },
	{
		path: "/contact",
		name: "Contact",
		element: <Contact />,
		nodeRef: createRef<HTMLDivElement>(),
		locked: false,
		hidden: false,
	},
];
