import React from "react";
import "./index.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

// portfolio images
import port1 from "../../../../images/projects/portfolio/portfolio-sshot.png";
import port2 from "../../../../images/projects/portfolio/code-sshot.png";

import mmover from "../../../../images/projects/mousemover/mmover-sshot.png";


// import required modules
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";

function Projects() {
	return (
		<div className="proj-content">
			<ProjectElem
				src={[port1, port2]}
				title={"My Portfolio"}
				text={text1}
				link={""}
				tags={[
					"Web Dev & Design",
					"React",
					"TypeScript",
					"Networking",
					"Express",
					"Rest API",
				]}
			></ProjectElem>
			<ProjectElem
				src={[mmover]}
				title={"MouseMover"}
				text={text2}
				link={"https://github.com/Veridus/MouseMover"}
				tags={["C#", "Windows Forms", "Open Source", "Mouse Mover"]}
			></ProjectElem>
			{/* <ProjectElem
				img={"https://loremflickr.com/430/320?random=3"}
				title={"My Portfolio"}
				text={text}
				link={"https://github.com/Veridus/Portfolio"}
				tags={["Web App", "React", "TypeScript", "Unix", "Networking"]}
			></ProjectElem>
			<ProjectElem
				img={"https://loremflickr.com/430/320?random=4"}
				title={"My Portfolio"}
				text={text}
				link={"https://github.com/Veridus/Portfolio"}
				tags={["Web App", "React", "TypeScript", "Unix", "Networking"]}
			></ProjectElem> */}
		</div>
	);
}

export default Projects;

type ProjectElemProps = {
	src: string[];
	title: string;
	text: string;
	link: string;
	tags: string[];
};

function ProjectElem(props: ProjectElemProps) {
	return (
		<div className="proj-elem">
			<div className="proj-swiper">
				<Swiper
					spaceBetween={0}
					centeredSlides={true}
					effect={"fade"}
					// autoplay={{
					// 	delay: 5000,
					// 	disableOnInteraction: true,
					// }}
					pagination={{
						clickable: false,
					}}
					navigation={true}
					modules={[EffectFade, Autoplay, Pagination, Navigation]}
					className={"Swiper_" + props.title.replace(" ", "_")}
				>
					{props.src.map((src) => (
						<SwiperSlide key={src}>
							<img src={src} alt={src} />
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			<div className="proj-elem-text">
				<h2>{props.title}</h2>
				<p>{props.text}</p>
				<div className="proj-elem-link">
					<a href={props.link}>{props.link}</a>
				</div>
				<div className="proj-elem-text-tags">
					{props.tags.map((tag) => (
						<div key={tag} className="proj-elem-text-tags-tag">
							{tag}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

const text1 =
	"Built as a React TypeScript web application, it offers a dynamic and engaging user experience. The sleek and minimalistic design, ensures that the focus remains on the content. To ensure security and privacy, I've implemented password-secured areas that grant access only to authorized users. The backend is powered by an Express REST API, and it's all hosted and configured on my personal home server, ensuring a seamless and personalized browsing experience. Explore my work and credentials in style with this custom-built web portfolio.";

const text2 =
	'"MouseMover" is a straightforward C# application that automatically moves the mouse cursor across the screen, incorporating a Windows Forms interface for ease of use. Developed with the GPL-3.0 license, this project targets users seeking a basic tool for automating mouse movements without complex setup requirements. The application is open-source and available on GitHub. Feel free to contribute and improve the project.';
