import "./index.css";
import { SkillsElem, SkillsHeader } from "./skillsComps";

function Skills() {
	return (
		<div className="skills-div">
			<div className="skills-body scroll">
				<SkillsHeader title="Coding Skills" />
				{codingLangs.map((skill) => (
					<SkillsElem key={skill.name} {...skill} />
				))}
				<SkillsHeader title="Technical Skills" />
				{tech.map((skill) => (
					<SkillsElem key={skill.name} {...skill} />
				))}
				<SkillsHeader title="Framework Skills" />
				{frameworks.map((skill) => (
					<SkillsElem key={skill.name} {...skill} />
				))}
				<SkillsHeader title="App Skills" />
				{apps.map((skill) => (
					<SkillsElem key={skill.name} {...skill} />
				))}
				<SkillsHeader title="Soft Skills" />
				{soft.map((skill) => (
					<SkillsElem key={skill.name} {...skill} />
				))}

				<SkillsHeader title="Languages" />
				{langs.map((skill) => (
					<SkillsElem key={skill.name} {...skill} />
				))}
			</div>
		</div>
	);
}

export default Skills;

type SkillItem = {
	name: string;
	skill: number;
	half?: boolean;
};

// Define a generic sorting function
const sortBySkill = (a: SkillItem, b: SkillItem): number => {
	const aValue = a.skill + (a.half ? 0.5 : 0);
	const bValue = b.skill + (b.half ? 0.5 : 0);
	return bValue - aValue; // Sort in descending order
};

const codingLangs: SkillItem[] = [
	{ name: "HTML", skill: 4, half: true },
	{ name: "CSS", skill: 3 },
	{ name: "C#/.Net", skill: 3 },
	{ name: "Java-/TypeScript", skill: 3 },
	{ name: "Java", skill: 2, half: true },
	{ name: "Python", skill: 2, half: true },
	{ name: "C++", skill: 2 },
	{ name: "Lua", skill: 1, half: true },
].sort(sortBySkill);

const tech: SkillItem[] = [
	{ name: "Networking", skill: 4 },
	{ name: "Video Streaming", skill: 4 },
	{ name: "Version Control", skill: 4, half: true },
	{ name: "Databases", skill: 3, half: true },
	{ name: "Web Development", skill: 4 },
	{ name: "Mobile App Development", skill: 2, half: true },
	{ name: "Software Architecture", skill: 3 },
	{ name: "API Design & Development", skill: 3 },
	{ name: "Security Practices", skill: 1, half: true },
	{ name: "Performance Optimization", skill: 1, half: true },
	{ name: "Operating Systems", skill: 2 },
	{ name: "UI Design", skill: 2, half: true },
].sort(sortBySkill);

const frameworks: SkillItem[] = [
	{ name: "React", skill: 3, half: true },
	{ name: "Node.js", skill: 2, half: true },
	{ name: "Express", skill: 2 },
	{ name: "ASP.NET", skill: 2 },
	{ name: "Unity", skill: 2 },
	{ name: "Electron", skill: 1, half: true },
	{ name: "FastAPI", skill: 3 },
	{ name: "Arduino", skill: 2, half: true },
].sort(sortBySkill);

const apps: SkillItem[] = [
	{ name: "Apache", skill: 4 },
	{ name: "Docker", skill: 2, half: true },
	{ name: "MongoDB", skill: 2, half: true },
	{ name: "MySQL", skill: 4 },
	{ name: "Firebase", skill: 2 },
	{ name: "Git", skill: 4 },
	{ name: "GitHub", skill: 4 },
	{ name: "Rally", skill: 3, half: true },
	{ name: "Jira", skill: 3, half: true },
	{ name: "Confluence", skill: 3 },
	{ name: "Jenkins", skill: 0, half: true },
	{ name: "NPM/Yarn", skill: 2, half: true },
].sort(sortBySkill);

const soft: SkillItem[] = [
	{ name: "Communication", skill: 4 },
	{ name: "Teamwork", skill: 4 },
	{ name: "Problem-solving", skill: 4, half: true },
	{ name: "Adaptability", skill: 4 },
	{ name: "Conflict Resolution", skill: 2, half: true },
	{ name: "Leadership", skill: 3, half: true },
	{ name: "Time Management", skill: 3 },
].sort(sortBySkill);

const langs: SkillItem[] = [
	{ name: "German", skill: 5 },
	{ name: "English", skill: 4, half: true },
	{ name: "French", skill: 3 },
	{ name: "Romanian", skill: 3, half: true },
].sort(sortBySkill);
