import TimeLine from "../../../../Components/TimeLine";
import { useUnlockContext } from "../../../../Context/UnlockContext";
import UnlockScreen from "../UnlockScreen";
import "./index.css";

function Education() {
	const { isUnlocked } = useUnlockContext();

	if (!isUnlocked) {
		return <UnlockScreen />;
	} else {
		return (
			<div className="edu-content">
				<div className="edu-body">
					<TimeLine
						title="Robert-Bosch-Schule Ulm"
						date="10/2014-06/2020"
						text={text1}
						isFirst
					/>
					<TimeLine
						title="Dualehochschule BW Heidenheim"
						date="10/2020-04/2021"
						text={text2}
					/>
					<TimeLine
						title="Univ. of Applied Arts & Sciences Coburg"
						date="10/2021-curret"
						text={text3}
						isLast
					/>
				</div>
			</div>
		);
	}
}

export default Education;

const text1 = ["Highschool", "Mechatronics", "Abitur"];

const text2 = ["Bachelor of Engeneering (Mechanical engineering) (cancelled)"];

const text3 = ["Bachelor of Engeneering (Computer science)"];
