import axios, { AxiosError } from "axios";

type LoginResponse = {
	message: string;
	status: number;
};

export async function CheckPassword(password: string): Promise<LoginResponse> {
	try {
		const response = await axios.post<LoginResponse>("/login", { password });
		return {
			message: response.data.message,
			status: response.status,
		};
	} catch (error) {
		const axiosError = error as AxiosError<LoginResponse>;
		if (axiosError.response) {
			return {
				message:
					(axiosError.response.data as LoginResponse).message ||
					"An error occurred during login.",
				status: axiosError.response.status,
			};
		} else if (axiosError.request) {
			return {
				message: "No response received from the server.",
				status: 0, // or a specific error code
			};
		} else {
			return {
				message: axiosError.message || "An unexpected error occurred.",
				status: 0, // or a specific error code
			};
		}
	}
}
