import React from "react";
import "./index.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import img1 from "../../../../images/home/IMG_0879.jpg";
import img2 from "../../../../images/home/20201031_135618.jpg";
import img3 from "../../../../images/home/1A8BA107-01A3-4D91-ABDE-C1458107BBAD.jpeg";

// import required modules
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper/modules";

function Home() {
	return (
		<div className="home-div">
			<>
				<Swiper
					spaceBetween={0}
					centeredSlides={true}
					effect={"fade"}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false,
					}}
					pagination={{
						clickable: false,
					}}
					navigation={false}
					modules={[EffectFade, Autoplay, Pagination, Navigation]}
					className="Swiper"
				>
					<SwiperSlide>
						<img src={img1} alt="img1" />
					</SwiperSlide>
					<SwiperSlide>
						<img src={img2} alt="img2" />
					</SwiperSlide>
					<SwiperSlide>
						<img src={img3} alt="img3" />
					</SwiperSlide>
				</Swiper>
			</>
		</div>
	);
}

export default Home;
