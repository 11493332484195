import React, { createContext, useState, useContext, ReactNode } from "react";

interface UnlockContextType {
	isUnlocked: boolean;
	setUnlocked: (unlocked: boolean) => void;
}

const UnlockContext = createContext<UnlockContextType | undefined>(undefined);

export const useUnlockContext = () => {
	const context = useContext(UnlockContext);
	if (!context) {
		throw new Error("useUnlockContext must be used within a UnlockProvider");
	}
	return context;
};

interface Props {
	children: ReactNode;
}

export const UnlockProvider: React.FC<Props> = ({ children }) => {
	const [isUnlocked, setUnlocked] = useState<boolean>(false);

	const value = {
		isUnlocked,
		setUnlocked,
	};

	return <UnlockContext.Provider value={value}> {children} </UnlockContext.Provider>;
};
